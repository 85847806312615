import React,{useState} from 'react'
import Heading from '../../components/Heading/Heading';
import { syncTablesData } from '../../api/apiHandler'


export default function SyncDb(props) {
    const [ process, setProcess] = useState({ name: '', table: '' });
    const [ status, setStatus] = useState(null);

    const apiCall = async (data) => {
        if (!data.name && !data.table) { return false; }
        setProcess(data);
        setStatus('Processing...');

        let result = await syncTablesData(data);
        if (result && result.data && (result.data).code === 1) {
            setStatus((result.data).message);
        } else {
            setStatus((result.data).message);
        }
    }

  return (
    <section className="content home">
        <Heading title="Sync Data"/>
        <div className="container-fluid">
        <div className="row clearfix social-widget">
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect facebook-widget">
                    <div className="icon"><i className="zmdi zmdi-online"></i></div>
                    <div className="content">
                        <div className="text">Avatar Sync</div>
                        <div className="sync-data">{(process.name !== null && process.name === 'Avatar') ? status : <button onClick={() => { apiCall({ name: 'Avatar', table: 'tbl_avatar' }); }}>Sync Avatar</button> }</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect facebook-widget">
                    <div className="icon"><i className="zmdi zmdi-accounts"></i></div>
                    <div className="content">
                        <div className="text">Funzone Sync</div>
                        <div className="sync-data">{(process.name !== null && process.name === 'Funzone') ? status : <button onClick={() => { apiCall({ name: 'Funzone', table: 'tbl_fun_zone' }); }}>Sync Funzone</button> }</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect facebook-widget">
                    <div className="icon"><i className="zmdi zmdi-accounts"></i></div>
                    <div className="content">
                        <div className="text">Tutorial Sync</div>
                        <div className="sync-data">{(process.name !== null && process.name === 'Tutorial') ? status : <button onClick={() => { apiCall({ name: 'Tutorial', table: 'tbl_tutorials' }); }}>Sync Tutorial</button> }</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}